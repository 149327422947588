// /* eslint-disable jsx-a11y/anchor-is-valid */
// import {useEffect, useState} from 'react'
// import * as Yup from 'yup'
// import clsx from 'clsx'
// import {useFormik} from 'formik'
// import {useAuth} from '../core/Auth'
// import Cookies from 'js-cookie'


// const loginSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('Wrong email format')
//     .max(50, 'Maximum 50 symbols')
//     .required('Email is required'),
//   password: Yup.string()
//     .max(50, 'Maximum 50 symbols')
//     .required('Password is required'),
// })

// const initialValues = {
//   email: '',
//   password: '',
// }

// /*
//   Formik+YUP+Typescript:
//   https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
//   https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
// */

// export function Login() {
//   const [loading, setLoading] = useState(false);
//   const URL = process.env.REACT_APP_BASE_URL;
//   const {setCurrentUser} = useAuth();
//   const [error, setError] = useState("")

  
//   const formik = useFormik({
//     initialValues,
//     validationSchema: loginSchema,
//     onSubmit: (values) => {
//       setLoading(true);
      
//       fetch(`${URL}/auth/login`, {
//         method: "post",
//         headers: {
//           "Content-Type": "application/json"
//         },
//         body: JSON.stringify(values)
//       })
//       .then(response => {
//         if (!response.ok) {
//           setError("Invalid e-mail or password")
//           setLoading(false)
//           throw new Error(`HTTP error! Status: ${response} glgl`);
//         }
//         return response.json();
//       })
//       .then(data => {
//         console.log(data, "boobooo");

//         Cookies.set('authorized', data.token);
//         setCurrentUser(data.token);
//         setLoading(false);
//       })
//       .catch(error => {
//         console.error(error);
//         setLoading(false);
//       });
//     },
//   });

//   return (
//     <form
//       className='form w-100'
//       onSubmit={formik.handleSubmit}
//       noValidate
//       id='kt_login_signin_form'
//     >
//       {/* begin::Heading */}
//       <div className='text-center mb-11'>
//         <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
//       </div>
//       {/* begin::Heading */}

//       {/* begin::Separator */}
//       <div className='separator separator-content my-14'>
//         <span className='w-125px text-gray-500 fw-semibold fs-7'>Laportiva Admin Page</span>
//       </div>
//       {/* end::Separator */}

//       {formik.status ? (
//         <div className='mb-lg-15 alert alert-danger'>
//           <div className='alert-text font-weight-bold'>{formik.status}</div>
//         </div>
//       ) : (
//         <div className='mb-10 bg-light-info p-8 rounded'>
//           <div className='text-info'>
//             Use account <strong>email</strong> and password <strong>****</strong> to
//             continue.
//           </div>
//         </div>
//       )}

//       {/* begin::Form group */}
//       <div className='fv-row mb-8'>
//         <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
//         <input
//           placeholder='Email'
//           autoComplete='off'
//           {...formik.getFieldProps('email')}
//           className={clsx(
//             'form-control bg-transparent',
//             {'is-invalid': formik.touched.email && formik.errors.email},
//             {
//               'is-valid': formik.touched.email && !formik.errors.email,
//             }
//           )}
//           type='email'
//           name='email'
//         />
//         {formik.touched.email && formik.errors.email && (
//           <div className='fv-plugins-message-container'>
//             <span role='alert'>{formik.errors.email}</span>
//           </div>
//         )}
//       </div>
//       {/* end::Form group */}

//       {/* begin::Form group */}
//       <div className='fv-row mb-3'>
//         <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
//         <input
//           type='password'
//           placeholder='password'
//           autoComplete='off'
//           {...formik.getFieldProps('password')}
//           className={clsx(
//             'form-control bg-transparent',
//             {
//               'is-invalid': formik.touched.password && formik.errors.password,
//             },
//             {
//               'is-valid': formik.touched.password && !formik.errors.password,
//             }
//           )}
//         />
//         {formik.touched.password && formik.errors.password && (
//           <div className='fv-plugins-message-container'>
//             <div className='fv-help-block'>
//               <span role='alert'>{formik.errors.password}</span>
//             </div>
//           </div>
//         )}
//         <p style={{color:"red", fontSize:"16px"}}>{error && error}</p>
//       </div>
//       {/* end::Form group */}

//       {/* begin::Wrapper */}
//       <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>

//       </div>
//       {/* end::Wrapper */}

//       {/* begin::Action */}
//       <div className='d-grid mb-10'>
//         <button
//           type='submit'
//           id='kt_sign_in_submit'
//           className='btn btn-primary'
//         >
//           {!loading && <span className='indicator-label'>Continue</span>}
//           {loading && (
//             <span className='indicator-progress' style={{display: 'block'}}>
//               Please wait...
//               <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
//             </span>
//           )}
//         </button>
//       </div>
//       {/* end::Action */}
//     </form>
//   )
// }


/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useAuth } from '../core/Auth'
import Cookies from 'js-cookie'
import axios from 'axios'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const URL = process.env.REACT_APP_BASE_URL
  const { setCurrentUser } = useAuth()
  const [error, setError] = useState("")

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const response = await axios.post(`${URL}/auth/login`, values, {
          headers: {
            "Content-Type": "application/json"
          }
        })

        const data = response.data
        Cookies.set('authorized', data.token)
        setCurrentUser(data.token)
      } catch (err) {
        setError("Invalid e-mail or password")
        console.error(err)
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Separator */}
      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Laportiva Admin Page</span>
      </div>
      {/* end::Separator */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>email</strong> and password <strong>****</strong> to
            continue.
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          placeholder='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
        <p style={{ color: "red", fontSize: "16px" }}>{error && error}</p>
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>

      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
